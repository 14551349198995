import React, { Component, useEffect, useState } from "react"
import styled from "styled-components"
import { Spinner, Container, Modal, Button } from "react-bootstrap"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import { Match, navigate } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { getNodesFromQuery } from "../service/helper"
import Cta from "../components/Cta"
import queryString from "querystring"
import { getPreviewData } from "../service/helper"
import { DynamicContactForm } from "../components/DynamicContactForm"
//import axios from "axios"
// test for github 2
const QuestionnairePreviewTemplate = props => {
  const [data, setData] = useState(null)

  useEffect(() => {
    getPreviewData(props)
      .then(r => {
        console.log(r)
        setData(r)
      })
      .catch(err => console.log(err))
  }, [])
  console.log("request data", data)
  if (data) {
    return (
      <MyFormContainer>
        <h2 style={{ flexBasis: "100%" }}>Schreibe uns eine Nachricht!</h2>
        <div className="form-content-container">
          <span>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>

          <DynamicContactForm questionnaire={data} />
        </div>
      </MyFormContainer>
    )
  } else {
    return "loading"
  }
}

const MyFormContainer = styled(Container)`
  flex: 0 0 50%;
  background-color: #eeeeee;
  height: 100%;
  padding: 30px;
  margin: 50px auto;
  border: 1px solid #888888;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .form-content-container {
    display: flex;
  }
  @media (max-width: ${props => props.theme.mobile}) {
    flex-direction: column;
    .form-content-container {
      flex-direction: column;
    }
  }
`
export default QuestionnairePreviewTemplate
