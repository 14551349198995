import React, { useEffect } from "react"
import BlockContent from "@sanity/block-content-to-react"
import blockSerializer from "../service/blockSerializer"
import { Container, Row } from "react-bootstrap"
import { imageUrlFor } from "../service/helper"

import styled from "styled-components"

import gsap from "gsap" // for animations
import ScrollTrigger from "gsap/ScrollTrigger" // for animations

// White background container for content on landing page
const MyContainer = styled(Container)`
  height: 100%;
  padding: 50px;
  background-color: ${props => props.theme.navLinkColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  img {
    width: 100%;
  }
  z-index: 2;
`

// Wrapper for MyContainer including background image
const ContentWrapper = styled.div`
  position:relative;
  width: 100%;
  padding-top: 10vh;
  padding-bottom: 10vh;
  margin-bottom: ${props => (props.total - 1 === props.idx ? "0" : "-15vh")};
  z-index:${props => props.total - props.idx};
  background-color: ${props =>
    props.idx % 2 === 0 ? props.theme.primaryDark : props.theme.primaryLight};
  background-image: url('${props => props.imageUrl}');
  background-size: cover;
  clip-path: polygon(0 0%, 100% 0%, 100% ${props =>
    props.idx % 2 == 0 ? "100%" : "calc(100% - 7vh)"}, 0% ${props =>
  props.idx % 2 == 1 ? "100%" : "calc(100% - 7vh)"});
  box-sizing: border-box;
  h1 {
    color: #fafafa;
    text-align: left;
    padding-bottom: 5%;
    padding-left: 50px;
    padding-right: 20px;
  }
`

const StyledTitle = styled(Container)`
  position: sticky;
  padding-bottom: 10vh;
  top: 10vh;
  z-index: -1;
  @media (max-width: ${props => props.theme.mobile}) {
    position: relative;
  }
`

// Article block from sanity landing page section
const ArticleBlock = props => {
  return (
    <ContentWrapper
      // background image
      imageUrl={imageUrlFor(props.image).width(960).blur(30).url()}
      id={`lp-section-wrapper-${props.id}`}
      idx={props.idx}
      total={props.total}
    >
      <StyledTitle>
        <Row>
          <h1 id={`lp-section-title-${props.id}`}>{props.title}</h1>
        </Row>
      </StyledTitle>
      <MyContainer id={`lp-section-content-${props.id}`}>
        <BlockContent
          blocks={props.content ? props.content : props._rawContent}
          serializers={blockSerializer}
        />
      </MyContainer>
    </ContentWrapper>
  )
}

export default ArticleBlock
